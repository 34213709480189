import React from 'react';
//import ReactDOM from 'react-dom';
import '../index.css';


export default function navbar(){
    return(
        <nav class="navbar navbar-expand-lg navbar-dark AzulMedio">
        <div class="container-md">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <a class="navbar-brand" href="#">Navbar</a>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Cadastros
                </a>
                <ul class="dropdown-menu AzulSimples" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item active" href="#">Clientes</a></li>
                  <li><a class="dropdown-item" href="#">Fornecedores</a></li>
                  <li><a class="dropdown-item" href="#">Produtos</a></li>
                  <li><a class="dropdown-item" href="#">Orçamentos</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Relatorios
                </a>
                <ul class="dropdown-menu AzulSimples" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="#">Clientes</a></li>
                  <li><a class="dropdown-item" href="#">Fornecedores</a></li>
                  <li><a class="dropdown-item" href="#">Estoque</a></li>
                  <li><a class="dropdown-item" href="#">Vendas</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Manutenção
                </a>
                <ul class="dropdown-menu AzulSimples" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="#">Usuarios</a></li>
                  <li><a class="dropdown-item" href="#">Banco de Dados</a></li>
                  <li><a class="dropdown-item" href="#">Relatorios</a></li>
                  <li><a class="dropdown-item" href="#">Suporte Tecnico</a></li>
                </ul>
              </li>
            </ul>
            
          </div>
        </div>
      </nav>


    )
}   