import React from 'react';

export default function Editar(props){
    return(
        <div>
            <div class="modal fade" id="ModalEditar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Alterar Cliente</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" placeholder="Francisco Hipolito, Roger nagazaky"/>
                        <label for="floatingInput">Nome</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="number" class="form-control" id="floatingInput" placeholder="000.000.000-00"/>
                        <label for="floatingInput">CPF</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" placeholder="00.000.000.X"/>
                        <label for="floatingInput">RG</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="number" class="form-control" id="floatingInput" placeholder="R$ 1500,00"/>
                        <label for="floatingInput">Salario</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="date" class="form-control" id="floatingInput" placeholder="R$ 1500,00"/>
                        <label for="floatingInput">Data</label>
                    </div>

                    <div class="form-floating">
                        <select class="form-select" id="floatingSelectGrid" aria-label="Floating label select example">
                            <option selected>Qual o seu sexo?</option>
                            <option value="M">Masculino</option>
                            <option value="F">Feminino</option>
                        </select>
                        <label for="floatingSelectGrid">Sexo:</label>
                    </div>

                    <div class="form-floating">
                        <select class="form-select" id="floatingSelectGrid" aria-label="Floating label select example">
                            <option selected>qual sua cidade?</option>
                            <option value="1">Franca</option>
                            <option value="2">Ribeirão Preto</option>
                            <option value="3">São Paulo</option>
                        </select>
                        <label for="floatingSelectGrid">Cidade</label>
                    </div>
                
                
                </div>

                
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    <button type="button" class="btn btn-primary">Salvar Alteraçoes</button>
                </div>
                </div>
                </div>  
            </div>
        </div>
    )
}