import React, {Component} from 'react';
//import ReactDOM from 'react-dom';
import Navbar from './navbar'
import '../index.css';
import api from '../api';
import Item from './Lista/item';
import Add from './Lista/adicionar'
import Editar from './Lista/editar';
import Conferir from  './Lista/Conferir';
import Excluir from './Lista/excluir';





class Lista extends Component{
    state= {
        Clientes: [],
    }

    async componentDidMount(){
        const res = await api.get('');

        this.setState({Clientes: res.data});
    }
    
    render(){

        const {Clientes} = this.state;

    return(
        
        <div>
        <Navbar/>
        <div className="container-md ">
            {Clientes.map(cliente =>{

            })}
            
            <nav aria-label="breadcrumb AzulEscuro">
                <ol class="breadcrumb ">
                    <li class="breadcrumb-item  active" aria-current="page">Cadastros / Clientes</li>
                </ol>
            </nav>
            <div className="AzulPale">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col show">Cod.</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Salario</th>
                        <th scope="col">Data de Nascimento</th>
                        <th scope="col">Cidade</th>
                        <th scope="col"><div class="bnt btn-primary" data-bs-toggle="modal" data-bs-target="#ModalAdcionar">+</div></th>
                    </tr>
                </thead>
                <tbody>
                    {console.log(Clientes)}
                    {Clientes.map(cliente => (
                    <Item 
                    key = {cliente.id}
                    cod= {cliente.id} 
                    nome={cliente.nome} 
                    nascimento={cliente.dt_nascimento}
                    salario={cliente.salario}
                    cidade={cliente.id_cidade}
                />

                ))}
                    
                </tbody>
                </table>
            </div>  
        <Add/>
        <Excluir/>
        <Editar/>
        <Conferir/>
      
        </div>
        </div>
    )
}};


export default Lista;