import ReactDOM from 'react-dom';
import React from 'react';
//import Navbar from './componentes/navbar';
//import Footer from './componentes/footer';
import Lista from './componentes/lista';



ReactDOM.render(<Lista />, document.getElementById('root'));

//const el = document.getElementById('root')
//ReactDOM.render(
    
//    <div>
//        <Navbar/>
//        <Lista/>
//        <Footer/>
//
//    </div>

//, el)