import React from 'react';

export default function Item(props){
    return(
        <tr data-bs-toggle="modal" data-bs-target="#ModalConferir">
                <th scope="row">{props.cod}</th>
                <td>{props.nome}</td>
                <td>{props.salario}</td>
                <td>{props.nascimento}</td>
                <td>{props.cidade}</td>
                <td>
                    
                    <div class="bnt btn-danger" data-bs-toggle="modal" data-bs-target="#ModalExcluir">-</div>
                    <div class="bnt btn-warning" data-bs-toggle="modal" data-bs-target="#ModalEditar">*</div>
                    
                </td>
            </tr>
    )
}