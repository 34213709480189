import React from 'react';

export default function Excluir(props){
    return(
        <div>
            <div class="modal fade" id="ModalExcluir" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-body">
                    <h4>Cliente Exluido com sucesso!!</h4>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}